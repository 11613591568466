import './App.css';
import Content from './components/content';
import Sidebar from './components/sidebar';


require('./components/eventListeners');

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Sidebar />
        <Content />
      </div>
    </div>
  );
}

export default App;
