
export class NavigationLink{

    NavigationNormal(props){
        return(
            <a src={props.link} id={props.id} className={`navigation-link ${props.className}`}>
                {props.text}
            </a>
        );
    }
}