import react from "react";
import {NavigationLink} from "./navigationLink";

var navLink = new NavigationLink();

export default class Sidebar extends react.Component{
    constructor(){
        super();
        
        this.links = [
            {name:"Home", id:"home", link:"#homeBlock"},
            {name:"About", id:"about", link:"#aboutBlock"},
            {name:"Skills", id:"skills", link:"#skills"}
        ];
    }

    render(){
        return(
            <nav id="sidebar">
                <div className="sidebar-header">
                    <h4>Darvell Penland</h4>
                    {this.links.map((link, index) => 
                            <navLink.NavigationNormal text={link.name} id={link.id} link={link.link} className={index <= 0 ? "active" : ""} key ={index} />
                        )}
                </div>
            </nav>
        );
    }
}