import react from "react";

export default class Footer extends react.Component{

    render(){
        return(
                <div className="content-block" id="footerBlock">
                    <p id="footerContent">
                    This portfolio website was designed and programmed by myself for the expressed use to showcase my current skill set and experience.
                    </p>
                    <p>&copy; 2023 Vweb LLC.</p>
                    <p>Powered by Vweb LLC.</p>
                    <div id="footerEmblem"></div>
                </div>
        );
    }
}