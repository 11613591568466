import react from "react";
import $ from 'jquery';

export default class Skill extends react.Component{
    constructor(){
        super();
        
        this.state = {
            containerWidth: [],
            fillValue: []
        };
    }

    getRangeValue(actual, range, domain){
        let ar = (actual/range);
        let result = ar*domain;
        return(result);
    }

    setFill(fill, value){
        $(fill).animate({
            width: value,
        }, 1000, function(){});
    }

    detectScroll(self){
        $(window).scroll(function (event) {
            var scrollPosition = $(window).scrollTop();
            if(scrollPosition > 1620 && scrollPosition < 2200){
                self.setFill($(`#${self.props.name}Fill`),self.state.fillValue)
            }
        });
    }

    fillOnStartIfOnScreen(self){
        var scrollPosition = $(window).scrollTop();
            if(scrollPosition > 1620 && scrollPosition < 2200){
                self.setFill($(`#${self.props.name}Fill`),self.state.fillValue)
            }
    }

    componentDidMount(){
        let containerWidth = $('.bar-container').width();
        this.setState({ containerWidth: containerWidth});

        let fillValue = this.getRangeValue(this.props.level, 10, containerWidth);
        this.setState({ fillValue: fillValue});
    }
    

    render(){

        return(
                <div className="skill-card" id="skillCard">
                    <div className="label" id="title">{this.props.name ? this.props.name : 'Skill'}</div>
                    <div className="label" id="years">| Years: {this.props.years ? this.props.years : 'years'}</div>
                    <div className="bar-wrapper">
                        <div className="icon"><img src={this.props.icon ? this.props.icon : ''}></img></div>
                        <div className="bar-container">
                            <div className="bar-fill" id={`${this.props.name}Fill`}></div>
                        </div>
                    </div>
                    {this.fillOnStartIfOnScreen(this)}
                    {this.detectScroll(this)}
                </div>
        );
    }
}