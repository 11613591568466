import react from "react";
import About from "./about";
import Footer from "./footer";
import Home from "./home";
import Skills from "./skills";

export default class Content extends react.Component{
    render(){
        return(
            <div id="content">
                <Home />
                <About />
                <Skills />
                <Footer />
            </div>
        );
    }
}