import react from 'react';
import $ from 'jquery';

export default class PolygonGraph extends react.Component{
    constructor(){
        super();
    }

    getDimensions(element){
        return{
            width: element.width(),
            height: element.height()
        }
    }
    
    getCenterOffset(dimensions){
        return{
            x: dimensions.width/2,
            y: dimensions.height/2
        }
    }

    getPercentageValue(actual){
        return(actual/100);
    }

    getvalueOfPercentage(percentage, domain){
        return((this.getPercentageValue(percentage)*domain));
    }

    getPossibleValue(domain, actual){
        let percentageValue = this.getPercentageValue(actual);

        return(percentageValue*domain);
    }
    
    getRelativePosition(position, dimensions){
        //console.log(dimensions)
        dimensions = {width: dimensions.width, height: dimensions.height}

        let centerOffset = this.getCenterOffset(dimensions);

        let relativePositivePosition = {x: (this.getPlotRangeValue(position.x, 100, centerOffset.x) + centerOffset.x), y: (this.getPlotRangeValue(position.y, 100, centerOffset.y) + centerOffset.y)}
        return(relativePositivePosition);
    }

    getPlotRangeValue(actual, range, domain){
        let ar = (actual/range);
        let result = ar*domain;
        return(result);
    }

    plotPoint(element, index, value){
        let dimensions = this.getDimensions(element);
        
        let pointDimensions = { width: 10, height:10 };
        let pointCenterOffset = this.getCenterOffset(pointDimensions);

        let location = {x: value, y: value}
        
        //console.log(location)
    
        if(index === 0){
            location = {x:0, y:value*-1};
            location = this.getRelativePosition(location, dimensions);
            location = {x: location.x, y: location.y+pointCenterOffset.y};
        }

        if(index === 1){
            location = this.getRelativePosition(location, dimensions);
            location = {x: location.x-pointCenterOffset.x, y: location.y-pointCenterOffset.y}
        }

        if(index === 2){
            location = {x:value*-1, y:value*1};
            location = this.getRelativePosition(location, dimensions);
            location = {x: location.x+pointCenterOffset.x, y: location.y-pointCenterOffset.y}
        }

        //element.append(`<div id="point${index}" class="point" style="width: ${pointDimensions.width}px; height: ${pointDimensions.height}px; top:${location.y}px; right:${location.x}px;"></div>`);



        return(location);
    }

    drawLine(points, dimensions){
        let c = document.getElementById('polygonCard');
        let ctx = c.getContext('2d');

        ctx.strokeStyle = '#000';

        let locationCenter = this.getRelativePosition({x:0, y:0+20}, dimensions);
        

        points.map((point, index) => {
            let maxPoints = {
                x: 300,
                y: 300
            }

            if(index === 0){
                maxPoints.x = 300;
                maxPoints.y = 30;
                
            }

            if(index === 1){
                maxPoints.x = 595;
                maxPoints.y = 520;
                
            }

            if(index === 2){
                maxPoints.x = 5;
                maxPoints.y = 520;
                
            }

            ctx.beginPath();
            ctx.moveTo(locationCenter.x, locationCenter.y);
            ctx.lineTo(maxPoints.x, maxPoints.y);
            ctx.stroke();
        });
    }

    drawPolyRings(points){
        let c = document.getElementById('polygonCard');
        let ctx = c.getContext('2d');

        let rings = [{points:[{x:300, y:30}, {x:595, y:520}, {x:5, y:520}]}, {points:[{x:300, y:150}, {x:495, y:455}, {x:105, y:455}]}, {points:[{x:300, y:250}, {x:395, y:391}, {x:205, y:391}]}];

        ctx.strokeStyle = '#000';

        
        rings.map((ring)=>{
            ctx.beginPath();
            //ctx.moveTo(rings[0].points[0].x, 100);

            ring.points.map((point) => {
                //console.log(point)
                ctx.lineTo(point.x, point.y);
            });
            ctx.closePath();
            ctx.stroke();
        });
    }

    drawPoly(points){
        let c = document.getElementById('polygonCard');
        let ctx = c.getContext('2d');

        ctx.fillStyle = 'rgba(184, 248, 245, 0.40)';
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.beginPath();
        //ctx.moveTo(points[0].x, points[0].y);
        
        points.map((point)=>{
            ctx.lineTo(point.x, point.y);
        });

        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    }

    componentDidMount(){
        let element = $('#polygonCard');
        let dimensions = this.getDimensions(element);

        let abilities = [{name:'test', value:50}, {name:'sets', value:70}, {name:'tset', value:100}];

        let points = [];
        
        abilities.map((ability, index) => {
            points.push(this.plotPoint(element, index, ability.value));
        });

        this.drawLine(points, dimensions);
        this.drawPolyRings(points);
        this.drawPoly(points);

        //console.log(points)
    }

    render(){
        return(
                <canvas className="polygon-card" id="polygonCard" width="600" height="550">
                   
                </canvas>
        );
    }
}