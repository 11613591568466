import react from "react";
import PolygonGraph from "./polygonGraph";
import Skill from "./skill";

let skills = [
    {name: 'HTML5', level: 9, years:15, badge: 'https://clipground.com/images/html-logo-png-3.png'},
    {name: 'CSS3', level: 9, years:15, badge: 'https://www.logolynx.com/images/logolynx/8f/8fb97dec724d750d2085173816712ffc.png'},
    {name: 'Javascript', level: 7, years:8, badge: 'https://zetta-solutions.net/images/logos/javascript.png'},
    {name: 'CSharp', level: 7, years:6, badge: 'https://www.techbaz.org/Course/img/csharp-logo.png'},
];

let technologies = [
    {name: 'NET', level: 7, years:6, badge: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fgetcodify.com%2Fwp-content%2Fuploads%2F2016%2F07%2Fasp.net-border.png&f=1&nofb=1'},
    {name: 'Webpack', level: 5, years:3, badge: 'https://raw.githubusercontent.com/webpack/media/master/logo/icon-square-big.png'},
    {name: 'React', level: 6, years:2, badge: 'https://clipground.com/images/react-logo-png-7.png'},
    {name: 'NodeJS', level: 5, years:3, badge: 'https://pluspng.com/img-png/nodejs-png--400.png'},
];

let otherSkills = ['Agile Workflow Experience', 'GIT Source Control', 'ExpressJS & REST APIs', 'D3JS Data Visualization', 'NUnit C\# Testing', 'JestJS Javascript Testing', 'Mongo Database Integration ', 'Android Studio', 'Adobe Photoshop', 'Adobe Illustrator', 'Clip Studio', 'Vegas Pro'];

export default class Skills extends react.Component{

    render(){
        return(
                <div className="content-block" id="skillsBlock">
                    <div className="content-block-head-left">
                        <h1>Skills</h1>
                        <h3>What I know..</h3>
                        <p>Here are a few of the programming languages and technologies that I am familiar with and my proficiency with each.</p>
                        </div>
                        <div id="skillWrapper">
                        <div className="skill-panel technologies-panel">
                            <h3>Languages</h3>
                                {skills.map((skill, index)=>(
                                    <Skill key={index} name={skill.name} icon={skill.badge} years={skill.years} level={skill.level}/>
                                ))}
                            </div>
                            <div className="skill-panel frameworks-panel">
                                <h3>Technologies</h3>
                                {technologies.map((skill, index)=>(
                                    <Skill key={index} name={skill.name} icon={skill.badge} years={skill.years} level={skill.level}/>
                                ))}
                            </div>
                            <div className="skill-panel others-panel">
                                <h3>Others Skills</h3>
                                    <ul>
                                        {otherSkills.map((skill, index)=>(
                                            <li key={index}>
                                                <p key={index}>
                                                {skill}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                            </div>
                        </div>
                </div>
        );
    }
}